.container {
    padding: 20px;
  }
  
  .title {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }
  
  .card {
    padding: 20px;
    box-shadow: 0 4px 24px rgba(34, 41, 47, 0.1);
    border-radius: 12px; 
  }
  
  .avatar {
    background-color: #297bca;
  }
  
  .form {
    margin-top: 20px;
  }
  
  .editor {
    margin-top: 10px;
    min-height: 400px;
    border-radius: 12px; 
    :global(.ql-editor a) {
      color: inherit !important;
      text-decoration: underline !important;
    }
  }
  
  .inputField {
    border-radius: 12px;
  }
  
  .imageUpload {
    margin-top: 20px;
    text-align: center;
  }
  
  .imagePreviewContainer {
    position: relative;
    display: inline-block;
  }
  
  .imagePreview {
    margin-top: 10px;
    border-radius: 12px;
    width: 100%;
    max-width: 300px; 
  }
  
  .uploadButton {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    border-radius: 12px;
  }
  
  .buttonGroupTop {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .deleteIcon {
    position: absolute;
    top: -10px;  
    right: -10px;  
    color: red;
    background-color: white;
    border-radius: 50%;
    padding: 2px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);  
  }
  
  .imageSize {
    margin-top: 5px;
    color: gray;
  }
  
  
  .errorBorder {
    border: 2px solid red !important;
  }
  
  .errorText {
    color: red;
    font-size: 0.8rem;
    margin-top: 5px;
  }
  